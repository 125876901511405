import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { MAX_MATCHES } from "@/game-deadlock/constants/enums.mjs";
import type { ProfileHeroStats } from "@/game-deadlock/models/profileHeroStats.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import usePlayerHeroStatsAll from "@/game-deadlock/utils/use-player-hero-stats.mjs";
import SharedHeadShotStats from "@/shared-fps/HeadShotStats.jsx";
import sanitizeNumbersByObject from "@/shared-fps/sanitize-numbers-by-object.mjs";
import { classNames } from "@/util/class-names.mjs";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const DEFAULT_OPTION = "all";
const DEFAULT_HIT_STATS = {
  headshots: 0,
  bodyshots: 0,
  chestshots: 0,
  stomachshots: 0,
  legshots: 0,
};

export default memo(function HeadShotStats({ steamId }: { steamId: string }) {
  const {
    deadlock: {
      profileHeroStats: $profileHeroStats,
      heroes: $heroes,
      matchlist: $matchlist,
      matchlistMeta,
    },
  } = useSnapshot(readState);
  const { t } = useTranslation();
  const heroes = useEvalState($heroes);
  const matchlist = useEvalState($matchlist[steamId]);
  const matchesByProfile = useMemo(
    () =>
      matchlist?.map((i) => matchlistMeta[steamId]?.[i]).filter(filterErrState),
    [matchlist, matchlistMeta, steamId],
  );
  const profileHeroStats = useEvalState($profileHeroStats[steamId]);
  const playerHeroStats = usePlayerHeroStatsAll(steamId);

  const [selected, setSelected] = useState<string | number>(DEFAULT_OPTION); // :heroId
  const handleOnChange = useCallback(setSelected, [setSelected]);
  const options = useMemo(
    () =>
      Object.keys(profileHeroStats ?? {}).reduce(
        (acc, heroId) => {
          const hero = heroes?.[heroId];
          if (!hero) return acc;
          acc.push({
            text: ["", hero.heroName],
            value: heroId,
          });
          return acc;
        },
        [
          {
            text: ["common:difficulties.all", "All"],
            value: DEFAULT_OPTION,
          },
        ] as Array<{ text: Translation; value: string }>,
      ),
    [heroes, profileHeroStats],
  );
  const totalMatchesPlayed = useMemo(
    () => Math.min(MAX_MATCHES, sanitizeNumber(playerHeroStats.games_played)),
    [playerHeroStats.games_played],
  );
  const avgHeroStats = useMemo(() => {
    const result = createHitStats(playerHeroStats);
    for (const k in result)
      result[k] = Math.round(
        sanitizeNumber(result[k] / playerHeroStats.games_played),
      );
    return result;
  }, [playerHeroStats]);
  const lastNStats: LastNStats = useMemo(() => {
    // All hero stats
    if (selected === DEFAULT_OPTION) return avgHeroStats;
    // Hero stats
    const hitStats = createHitStats(profileHeroStats?.[selected]);
    sanitizeNumbersByObject(hitStats);
    return hitStats;
  }, [avgHeroStats, profileHeroStats, selected]);
  const matches: Array<HeadShotStatsMatch> = useMemo(
    () =>
      matchesByProfile
        ?.filter(
          (i) =>
            String(i.player.hero_id) === selected ||
            selected === DEFAULT_OPTION,
        )
        .slice(0, 10) // We only care about the last 10 games, prevent re-rendering after this
        .map((i) => {
          const stats = i.player.stats.at(-1);
          const hsPercent = sanitizeNumber(
            100 * (stats?.hero_bullets_hit_crit / stats?.hero_bullets_hit),
          );
          return {
            hsPercent,
            lastNAvgHS: sanitizeNumber(
              100 *
                (lastNStats.headshots /
                  (lastNStats.headshots + lastNStats.bodyshots)),
            ),
            imgUrl: Assets.getHeroPortrait(i.player.hero_id),
          };
        }) ?? [],
    [lastNStats.bodyshots, lastNStats.headshots, matchesByProfile, selected],
  );
  return (
    <Card padding={0}>
      <div
        className={
          classNames("flex justify-between gap-4 align-center", cssHitStats())
            ?.className
        }
      >
        <div className="type-title--bold">
          {t("common:pvpHitStats", "PvP Hit Stats")}
        </div>
        <Select
          selected={selected}
          options={options}
          onChange={handleOnChange}
          disabled={options.length <= 1}
        />
      </div>
      <SharedHeadShotStats
        hiddenLegshots
        lastNStats={lastNStats}
        matches={matches}
        matchCountHS={totalMatchesPlayed} // Override match count for BE calculated lifetime stats
        titleIconText={[
          "deadlock:match.avgHsLifetimeStat",
          "Avg. headshot is based on your lifetime stats",
        ]}
      />
    </Card>
  );
});

const cssHitStats = () => css`
  padding: var(--sp-4) var(--sp-6) 0 var(--sp-6);
  margin-bottom: calc(-1 * var(--sp-4));
`;

function createHitStats(heroStats?: ProfileHeroStats["x"]): LastNStats {
  const allshots = sanitizeNumber(heroStats?.hero_bullets_hit);
  const headshots = sanitizeNumber(heroStats?.hero_bullets_hit_crit);
  return {
    ...DEFAULT_HIT_STATS,
    headshots,
    bodyshots: allshots - headshots,
  };
}

type LastNStats = {
  headshots: number;
  bodyshots: number;
  chestshots: number;
  stomachshots: number;
  legshots: number;
};
type HeadShotStatsMatch = {
  hsPercent: number;
  lastNAvgHS?: number;
  imgUrl?: string;
};
