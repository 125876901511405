import type { Matchlist } from "@/game-deadlock/models/matchlist.mjs";

// The Profile.tsx Matchlist component got refactored and now the usage for almost all of our games is incorrect
// I will refactor the refactor on Matchlist after Deadlock release :') why was it refactored at all
export default function getMatchlist(
  matchlist: Error | Matchlist,
): Error | Array<{ matchId: string }> {
  if (matchlist instanceof Error) return matchlist;
  return matchlist?.map?.((id) => {
    return { id, matchId: id };
  });
}
