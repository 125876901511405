import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import ProfileRank from "@/shared/ProfileRank.jsx";
import calcKDA from "@/shared-fps/calc-kda.mjs";
import { classNames } from "@/util/class-names.mjs";
import { useEvalState } from "@/util/eval-state.mjs";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default memo(function ProfileStats({ steamId }: { steamId: string }) {
  const {
    deadlock: { profileStats },
  } = useSnapshot(readState);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const profileStat = useEvalState(profileStats[steamId]);
  const stats = useMemo(() => {
    const gamesPlayed = sanitizeNumber(profileStat?.games_played);
    const shotsHit = sanitizeNumber(profileStat?.shots_hit);
    return [
      {
        right: gamesPlayed,
        left: t("common:matches", "Matches"),
      },

      {
        right: calcKDA(
          profileStat?.kills,
          profileStat?.deaths,
          profileStat?.assists,
        ),
        left: t("common:stats.kda", "KDA"),
      },
      {
        right: sanitizeNumber(
          profileStat?.last_hits / gamesPlayed,
        ).toLocaleString(language, { maximumFractionDigits: 1 }),
        left: t("common:stats.avgLastHits", "Avg. Last Hits"),
      },
      {
        right: sanitizeNumber(profileStat?.denies / gamesPlayed).toLocaleString(
          language,
          { maximumFractionDigits: 1 },
        ),
        left: t("common:stats.avgDenies", "Avg. Denies"),
      },
      {
        right: sanitizeNumber(
          profileStat?.net_worth / gamesPlayed,
        ).toLocaleString(language, { maximumFractionDigits: 1 }),
        left: t("common:stats.avgSouls", "Avg. Souls"),
      },
      {
        right: displayRate(
          shotsHit,
          shotsHit + sanitizeNumber(profileStat?.shots_missed),
          0,
          1,
        ),
        left: t("common:stats.accuracy", "Accuracy"),
      },
    ];
  }, [language, profileStat, t]);
  const winRate = useMemo(
    () => sanitizeNumber(profileStat?.wins / profileStat?.games_played),
    [profileStat?.games_played, profileStat?.wins],
  );
  return (
    <ProfileRank
      wins={sanitizeNumber(profileStat?.wins)}
      losses={sanitizeNumber(profileStat?.losses)}
      title={t("common:csgo.gameMode.casual", "Casual")}
      below={<Stats stats={stats} />}
      belowTitle=""
      color={winRate > 50 ? "var(--turq)" : "var(--shade2)"}
      name=""
      points=""
      barColor="var(--turq)"
      percentFill={winRate}
    />
  );
});

const Stats = memo(function Stats({
  stats,
}: {
  stats: Array<{
    right: number | string;
    left: string;
  }>;
}) {
  return (
    <div className={classNames("pad-sp-4", cssGrid())?.className}>
      {stats.map((i) => (
        <div
          key={i.left + i.right}
          className="flex align-center justify-between gap-4"
        >
          <div className="shade2" style={{ fontSize: "var(--sp-3)" }}>
            {i.left}
          </div>
          <div
            className="shade0 type-title--bold"
            style={{ fontSize: "var(--sp-3_5)" }}
          >
            {i.right}
          </div>
        </div>
      ))}
    </div>
  );
});

const cssGrid = () => css`
  display: grid;
  gap: var(--sp-2) var(--sp-4);
  grid-template-columns: repeat(2, 1fr);
  padding-top: 0;
`;
