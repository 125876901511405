import React, { useMemo } from "react";
import { Card } from "clutch/src/Card/Card.jsx";

import { isVolatile, readState } from "@/__main__/app-state.mjs";
import { isValidated } from "@/__main__/get-data.mjs";
import ProfileHeroes from "@/game-deadlock/components/ProfileHeroes.jsx";
import ProfileOverview from "@/game-deadlock/components/ProfileOverview.jsx";
import ProfilePerks from "@/game-deadlock/components/ProfilePerks.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import isObject from "@/util/is-object.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TABS = {
  OVERVIEW: "overview",
  HEROES: "heroes",
  PERKS: "perks",
};

export default function Profile() {
  const {
    parameters: [steamId, tab],
  } = useRoute();
  const {
    deadlock: { profiles, steam },
  } = useSnapshot(readState);
  const profile = profiles[steamId];
  const steamProfile = steam[steamId];
  const isLoaded = useMemo(() => {
    return !!(
      profile instanceof Error ||
      (isObject(profile) &&
        (Object.hasOwn(profile, isVolatile) ||
          Object.hasOwn(profile, isValidated)))
    );
  }, [profile]);
  const SelectedTab = useMemo(() => {
    switch (tab) {
      case TABS.HEROES:
        return <ProfileHeroes />;
      case TABS.PERKS:
        return <ProfilePerks />;
      default:
        return <ProfileOverview />;
    }
  }, [tab]);
  return (
    <ProfileLayout>
      <PageHeader
        imageRound
        title={steamProfile?.name || steamId}
        image={steamProfile?.avatar || Assets.getDeadlockLogo()}
      />
      {isLoaded ? (
        <Container>
          <SharedProfile>{SelectedTab}</SharedProfile>
        </Container>
      ) : (
        <Container>
          <div className="relative">
            <Card loading style={{ height: "var(--sp-48)" }} />
            <div className="absolute-center">
              <BlitzLogoLoading />
            </div>
          </div>
        </Container>
      )}
    </ProfileLayout>
  );
}

export function meta([steamId]) {
  const name = readState.deadlock.steam[steamId]?.name ?? "Unknown";
  return {
    title: [
      `deadlock:meta.profile.title`,
      `{{name}}'s - Deadlock Performance Overview`,
      { name },
    ],
    description: [
      `deadlock:meta.profile.description`,
      `View {{name}}'s Deadlock profile and see how they perform.`,
      { name },
    ],
  };
}
