import rangeBucket from "@/util/range-bucket.mjs";

const kdaColorRange = rangeBucket([
  [0, "#828790"],
  [1.5, "#978D87"],
  [3.0, "#C4A889"],
  [4.5, "#DEAF78"],
  [6.5, "#E6A85F"],
  [8.5, "#FF9417"],
]);

export function kdaColor(kda: number): string {
  return kdaColorRange[kda];
}
