import { css } from "goober";

export const HeroesList = () => css`
  .left {
    display: flex;
    alignitems: center;
    gap: var(-sp-2);
  }
  .meta {
    display: grid;
  }
  .right {
    display: grid;
  }
`;
