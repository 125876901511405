import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import HeadShotStats from "@/game-deadlock/components/HeadShotStats.jsx";
import HeroPerformance from "@/game-deadlock/components/HeroPerformance.jsx";
import DeadlockMatchTile from "@/game-deadlock/components/MatchTile.jsx";
import ProfileStats from "@/game-deadlock/components/ProfileStats.jsx";
import {
  SearchParamsDefault,
  SearchParamsEnum,
} from "@/game-deadlock/constants/enums.mjs";
import getMatchlist from "@/game-deadlock/utils/get-matchlist.mjs";
import getPlayer from "@/game-deadlock/utils/get-player.mjs";
import { MatchList, MatchTile } from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import { filterErrState } from "@/util/eval-state.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function ProfileOverview() {
  const {
    parameters: [steamId],
  } = useRoute();
  const [gameMode] = useQuery(SearchParamsEnum.Mode);
  const [hero] = useQuery(SearchParamsEnum.Hero);
  const {
    deadlock: { matchlist, matches },
  } = useSnapshot(readState);
  const matchlistByPlayer = useMemo(
    () => getMatchlist(matchlist[steamId]),
    [steamId, matchlist],
  );
  const matchlistRx = useMemo(() => {
    return (Array.isArray(matchlistByPlayer) ? matchlistByPlayer : [])
      .slice()
      .filter((i) => {
        const params = [gameMode, hero];
        if (
          params.every((i) => !i) ||
          params.every((i) => i === SearchParamsDefault)
        )
          return true;
        const match = filterErrState(matches[i.matchId]);
        if (!match) return true;
        const against = [];
        const player = getPlayer(steamId, match);
        if (player && hero && hero !== SearchParamsDefault)
          against.push(String(player.hero_id) === hero);
        if (gameMode && gameMode !== SearchParamsDefault)
          against.push(String(match.game_mode) === gameMode);
        if (!against.length) return true;
        return against.every(Boolean);
      });
  }, [gameMode, hero, matches, matchlistByPlayer, steamId]);

  return (
    <>
      <ProfileColumn className="sidebar">
        <ProfileStats steamId={steamId} />
        <HeroPerformance steamId={steamId} />
        <HeadShotStats steamId={steamId} />
      </ProfileColumn>
      <ProfileColumn className="main">
        <ProfileMatchlist>
          <MatchList matchList={matchlistByPlayer}>
            {matchlistRx?.map?.((i) => {
              const $match = matches[i.matchId];
              const match = filterErrState($match);
              return (
                <MatchTile
                  key={i.matchId}
                  id={i.matchId}
                  match={$match}
                  height={154}
                >
                  {!!match && (
                    <DeadlockMatchTile steamId={steamId} match={match} />
                  )}
                </MatchTile>
              );
            }) ?? [null]}
          </MatchList>
        </ProfileMatchlist>
      </ProfileColumn>
    </>
  );
}
