import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { ElementOf } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import PlayerInventory from "@/game-deadlock/components/MatchPlayerInventory.jsx";
import { MatchTileTabsEnum } from "@/game-deadlock/constants/enums.mjs";
import getSteam from "@/game-deadlock/fetches/steam.mjs";
import type { Match } from "@/game-deadlock/models/match.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import getPlayer from "@/game-deadlock/utils/get-player.mjs";
import { playerAccolades } from "@/game-deadlock/utils/player-match-accolades.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import Badge, { BadgeList } from "@/shared/PostMatchBadge.jsx";
import globals from "@/util/global-whitelist.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { steam3to64 } from "@/util/steam.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MAX_ACCOLADES = 2;

const Tabs = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-deadlock/components/MatchScoreboard.jsx"),
  [MatchTileTabsEnum.Statistics]: () =>
    import("@/game-deadlock/components/MatchTileStatistics.jsx"),
  [MatchTileTabsEnum.Hits]: () =>
    import("@/game-deadlock/components/MatchTileHits.jsx"),
};
const Labels = [
  {
    name: ["tft:matchtabs.scoreboard", "Scoreboard"] as Translation,
    key: MatchTileTabsEnum.Scoreboard,
  },
  {
    name: ["common:navigation.statistics", "Statistics"] as Translation,
    key: MatchTileTabsEnum.Statistics,
  },
  {
    name: ["val:stats.hits", "Hits"] as Translation,
    key: MatchTileTabsEnum.Hits,
  },
];

export default memo(function MatchListRow({
  steamId,
  match,
}: {
  steamId: string;
  match: Match;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    settings: {
      deadlock: { preferredMatchTab },
    },
  } = useSnapshot(readState);

  const player = useMemo(() => getPlayer(steamId, match), [steamId, match]);
  const {
    kills = 0,
    assists = 0,
    deaths = 0,
    stats = [],
    items = [],
  } = useMemo(() => player ?? ({} as ElementOf<Match["players"]>), [player]);

  const matchMins = match.duration_s ? match.duration_s / 60 : 1;
  const eogPlayerStats = stats.at(-1);
  const playerDamageDealt = eogPlayerStats?.player_damage || 0;
  const dmgPerMin = playerDamageDealt / matchMins;
  const playerSouls = eogPlayerStats.net_worth || 0;
  const soulsPerMin = playerSouls / matchMins;
  // const playerDenies = eogPlayerStats.denies || 0;
  // const deniesPerMin = playerDenies / matchMins;
  const isWin = match.winning_team === player.team;
  const kda = (kills + assists) / (deaths || 1);

  const handleOnExpand = useCallback(() => {
    if (!Array.isArray(match.players)) return;
    for (const i of match.players)
      getSteam({
        steamId3: i.account_id.toString(),
        steamId64: steam3to64(i.account_id.toString()),
      });
  }, [match.players]);

  const tabString = preferredMatchTab ? `?tab=${preferredMatchTab}` : "";
  const link = `/deadlock/match/${match.match_id}/${steamId}${tabString}`;

  const accolades = useMemo(
    () => playerAccolades({ steamId, matchData: match }),
    [steamId, match],
  );

  const accoladesTruncated = accolades.length - MAX_ACCOLADES;

  return (
    <MatchTileExpand
      isWin={isWin}
      image={{
        src: Assets.getHeroPortrait(player?.hero_id),
        alt: steamId,
        style: { translate: "0% 10%" },
      }}
      titles={[
        {
          text: isWin
            ? t("common:victory", "Victory")
            : t("common:defeat", "Defeat"),
          bold: true,
          color: isWin ? "var(--turq)" : "var(--red)",
        },
        {
          text: t("common:minutes", "{{minutes}} mins", {
            minutes: formatDuration(match.duration_s * 1000, "m:ss"),
          }),
        },
        // {
        //   text: t("deadlock:matchId", "Match ID: {{matchId}}", {
        //     matchId: matchlistMetaItem.match_id,
        //   }),
        // },
      ]}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={Tabs}
      tabsLabel={Labels}
      tabArgs={{
        matchId: match.match_id,
        steamId,
      }}
      urlWeb={`${globals.location.origin}${link}`}
      urlCanonical={link}
      matchDate={new Date(match.start_time * 1000)}
      // aside={
      //   accolades.length ? (
      //     <BadgeList className="column" data-size="small">
      //       {accolades.slice(0, MAX_ACCOLADES).map((a, i) => {
      //         return <Badge key={i} accolade={a.accolade} size="small" />;
      //       })}
      //     </BadgeList>
      //   ) : null
      // }
      aside={
        <PlayerInventory
          items={items}
          style={{ paddingBlockStart: "var(--sp-9)" }}
        />
      }
      onExpand={handleOnExpand}
    >
      <div style={{ display: "grid", gap: "var(--sp-4)" }}>
        <div className={`flex row gap-6`}>
          <div>
            <div className="type-subtitle--bold">
              {t("common:stats.kda", "{{kda}} KDA", {
                kda: kda.toLocaleString(language, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }),
              })}
            </div>
            <div className="type-caption shade1">
              {t("lol:displayKDA", "{{kills}} / {{deaths}} / {{assists}}", {
                kills,
                deaths,
                assists,
              })}
            </div>
          </div>
          <div data-tip={t("common:stats.damagePerMin", "Damage / Min.")}>
            <div className="type-subtitle--bold">
              {t("common:stats.damagePerMinValue", "{{dpm}} DPM", {
                dpm: dmgPerMin.toLocaleString(language, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              })}
            </div>
            <div className="type-caption shade1">
              {sanitizeNumber(playerDamageDealt).toLocaleString(language)}
            </div>
          </div>
          <div data-tip={t("deadlock:stats.soulsPerMin", "Souls / Min.")}>
            <div className="type-subtitle--bold">
              {t("deadlock:stats.spm", "{{spm}} SPM", {
                spm: soulsPerMin.toLocaleString(language, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              })}
            </div>
            <div className="type-caption shade1">
              {sanitizeNumber(playerSouls).toLocaleString(language)}
            </div>
          </div>
          {/* <div>
            <div className="type-subtitle--bold">
              {t("deadlock:stats.valueDeniesPerMin", "{{dpm}} Denies / Min.", {
                dpm: deniesPerMin.toLocaleString(language, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }),
              })}
            </div>
            <div className="type-caption shade1">
              {sanitizeNumber(playerDenies).toLocaleString(language)}
            </div>
          </div> */}
        </div>
        <div>
          {accolades.length ? (
            <BadgeList data-size="small">
              {accolades.slice(0, MAX_ACCOLADES).map((a, i) => {
                return <Badge key={i} accolade={a.accolade} size="small" />;
              })}
              {accoladesTruncated > 0 && (
                <Badge
                  style={{ marginInlineStart: "-0.35rem " }}
                  accolade={{
                    title: ["", `+${accoladesTruncated}`],
                  }}
                  size="small"
                />
              )}
            </BadgeList>
          ) : null}
        </div>
      </div>
    </MatchTileExpand>
  );
});
